import { useStaticQuery, graphql } from "gatsby";

export const useContact = () => {
  const data = useStaticQuery(graphql`
    query ContactQuery {
        wpPage(id: {eq: "cG9zdDoy"}) {
          s4Title
          s4prefix
        }
      }
    `)
  return data?.wpPage
}
