import React from 'react'
import Container from '../components/Container/Container'
import styled from 'styled-components'
import SmallTitle from '../components/SmallTitle/SmallTitle'
import BigTitle from '../components/BigTitle/BigTitle'
import hand from '../images/hand.svg'
import Slider1 from '../components/Slider/Slider1/Slider1'
import house from '../images/house.svg'
import Tiles from '../components/Tiles/Tiles'
import { usePossibilitiesData } from '../services/card/usePossibilitiesData'
import { useCardTitles } from '../services/card/useCardTitles'
import { useWhyWorth } from '../services/card/useWhyWorth'
import switchIcon from '../images/switchIcon.svg'






const breakpoint1 = '1400px'
const breakpoint2 = '900px'

// const featuresData = [
//     'Sterowanie oświetleniem',
//     'Zarządzanie bezpieczeństwem',
//     'Sterowanie zaciemnianem',
//     'Zarządzenie zużyciem energią',
//     'Sterowanie temperaturą',
//     'Zarządzanie i kontrola dostępu',
//     'Sterowanie systemy audio'
// ]

const CardStyled = styled.div`
    background-color: #FAF7F5;
    margin-top: -85px;
    padding: 130px 115px;
    @media (max-width: ${breakpoint1}) {
        padding: 130px 20px;
    }
`

const Wrapper = styled.div`
    padding: 0px 100px;
    @media (max-width: ${breakpoint2}) {
        padding: 0px 20px;
    }
`

const Description = styled.div`
    font-size: 14px;
    line-height: 28px;
    margin-bottom: 100px;
    padding-left: 50px;
    max-width: 540px;
    color: ${props => props?.theme?.colors?.lightGray};
`

const Features = styled.div`
    background-color: ${props => props?.theme?.colors?.white};
    margin-right: 100px;
    padding: 84px 140px 140px 140px;
    position: relative;
    max-width: 990px;
    display: flex;
    @media (max-width: ${breakpoint1}) {
        margin-right: 0px;
    }
    @media (max-width: ${breakpoint2}) {
        padding: 20px 20px 60px 20px;
    }
    @media (max-width: 750px) {
        padding: 20px;
    }
`

const FeaturesList = styled.ul`
    display: flex;
    flex-wrap: wrap;
    max-width: 570px;
    position: relative;
    z-index: 2;
    @media (max-width: ${breakpoint1}) {
        max-width: 100%;
    }

`

const Feature = styled.li`
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 20px;
    white-space: nowrap;
    min-width: 50%; 
`
const BlueSquare = styled.div`
    min-width: 10px;
    min-height: 10px;
    max-width: 10px;
    max-height: 10px;
    background-color: ${props => props?.theme?.colors?.blue};
    margin-right: 14px;
    margin-top: 4px;
    display: inline-block;
`
const Hand = styled.img`
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(45%, -64%);
    @media (max-width: ${breakpoint1}) {
        right: initial;
        left: 50%;
        height: 100%;
        transform: translateX(-50%);
        opacity: 0.15;
    }
`

const WhiteBox = styled.div`
    
    display: flex;
    flex-direction: column;
    align-items:center;
    position: relative;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        max-width: 800px;
        width: 100%;
        height: 100%;
        background-color: ${props => props?.theme?.colors?.white};
        transform: translate(-50%, 50px);
    }
`

const House = styled.img`
    display: block;
    margin: auto;
    position: relative;
    z-index:2;
    max-width: 100%;
    margin-bottom: -15px;
`

const SwitchIcon = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    @media (max-width: ${breakpoint2}) {
        display: none;
    }
`

const WhyWorth = styled.div`
    text-align: center;
`

const Card = () => {
    const possibilities = usePossibilitiesData() || [];
    const { s2Text = '', s2Title = '', s2prefix = '' } = useCardTitles() || {};
    const { s3prefix = '', s3Title = '', s3Tiles = '' } = useWhyWorth() || {};


    return (
        <Container>

            <CardStyled id='possibilities'>
                <Wrapper>
                    <SmallTitle>{s2prefix}</SmallTitle>
                    <BigTitle>{s2Title}</BigTitle>
                    <Description>
                        {s2Text}
                    </Description>
                    <Features>
                        <SwitchIcon src={switchIcon} alt='switch icon' />
                        <FeaturesList>
                            {
                                possibilities.map((feature, index) => <Feature key={index}><BlueSquare />{feature.text}</Feature>)
                            }
                        </FeaturesList>
                        <Hand src={hand} alt='hand' />
                    </Features>
                </Wrapper>
                <Slider1 />
                <WhyWorth id='why'>
                    <SmallTitle >
                        {s3prefix}
                    </SmallTitle>
                    <BigTitle>
                        {s3Title}
                    </BigTitle>
                </WhyWorth>
                <WhiteBox>
                    <House src={house} alt='house' />
                    <Tiles data={s3Tiles} />
                </WhiteBox>
            </CardStyled>
        </Container>
    )
}

export default Card
