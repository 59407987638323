import React from 'react'
import styled from 'styled-components'
import bg from '../images/front-background.jpg'
import Button from '../components/Button/Button'
import SwitchIcon from '../components/SwitchIcon/SwitchIcon'
import { useFront } from '../services/front/useFront'
import SmallTitle from '../components/SmallTitle/SmallTitle'
import {Link} from "gatsby";

const breakpoint1 = '1600px'
const breakpoint2 = '1350px'
const breakpoint3 = '800px'




const StyledFront = styled.div`
    min-height: calc(100vh + 85px);
    padding-bottom: 85px;
    background-image: url(${bg});
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
`

const Header = styled.div`
    display: flex;
    align-items: center;
    grid-area: header;
    font-size: 50px;
    color: ${props => props.theme.colors.gray};
    font-weight: 600;
    line-height: 1.4em;
    margin-left: 20%;
    poadding: 0 20px;
    @media (max-width: 1700px) {
        font-size: 37px;
    }
    @media (max-width: ${breakpoint1}) {
        margin-left: 0px;
        text-align:center;
    }
    @media (max-width: ${breakpoint3}) {
        font-size: 30px;
    }
`

const Grid = styled.div`
    margin-left: 200px;
    max-width: 100%;
    display: grid;
    // margin-top: 100px;
    grid-template-rows: 230px minmax(230px, auto) 230px;
    grid-template-columns: 1fr 320px 320px 160px;
    grid-template-areas:
        "header pink . green"
        "white . orange ."
        ". blue . ."
    ;
    @media (max-width: ${breakpoint1}) {
        grid-template-columns: 1fr 320px 320px 320px 320px;
        margin-left: 0px;
        grid-template-areas:
        ". . header header green"
        ". . white white white"
        " . blue orange pink . "
    ;
    }
    @media (max-width: ${breakpoint2}) {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas:
            ".  header header"
            " . white white"
            " blue orange pink"
        ;
    }
    @media (max-width: ${breakpoint3}) {
        
        grid-template-columns: 1fr ;
        grid-template-rows: 230px minmax(230px, auto) 230px 230px 230px;
        grid-template-areas:
            "header"
            " white"
            "blue "
            "orange"
            "pink";
        ;
        ${Header} {
            background-color: rgba(255,255,255,0.6);
            box-shadow: 0px 0px 20px 20px rgba(255,255,255,0.6);
        }
    }
   
    
    
`

const Box = styled.div`
    position: relative;
    padding: 30px;
    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        mix-blend-mode: multiply;
        backdrop-filter: blur(30px);
    }
`

const PinkBox = styled(Box)`
    grid-area: pink;
    &:before {
        background-color: ${props => props?.theme?.colors?.pink};
    }
`

const OrangeBox = styled(Box)`
    grid-area: orange;
    &:before {
        background-color: ${props => props?.theme?.colors?.orange};
    }
`

const GreenBox = styled(Box)`
    padding: 0;
    grid-area: green;
    &:before {
        background-color: ${props => props?.theme?.colors?.green};
    }
`

const WhiteBox = styled.div`
    background-color: #FAF7F5;
    grid-area: white;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    @media (max-width: 900px) {
        flex-direction: column;
        
    }

`

const BlueBox = styled(Box)`
    grid-area: blue;
    &:before {
        background-color: ${props => props?.theme?.colors?.blue};
    }
`

const Content = styled.div`
    position: relative;
`

const BoxTitle1 = styled.div`
    color: ${props => props?.theme?.colors?.gray};
    display: flex;
    justify-content: space-between;
`

const Title = styled.div`
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 10px;
`

const Number = styled.div`
    color: ${props => props?.theme?.colors?.white};
    opacity: 0.7;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 2px;
`

const BoxTitle2 = styled.div`
    font-size: 22px;
    line-height: 27px;
    letter-spacing: 2px;
    color: white; 
`
const Description = styled.div`
    color: ${props => props?.theme?.colors?.lightGray};
    span {
        color: ${props => props?.theme?.colors?.gray};
        font-weight: bold;
    }
`

const Grid2 = styled.div`
    display: grid;
    grid-template-columns: 1fr 320px 320px 160px;
    grid-template-rows: auto;
    margin-left: 200px;
    margin-top: 150px;
    margin-bottom: 30px;
    grid-template-areas:
        "area1 area2 area3 area4"
    ;
    @media (max-width: ${breakpoint1}) {
        grid-template-columns: 1fr 320px 320px 320px 320px;
        margin-left: 0px;
        margin-bottom: 0;
        grid-template-areas:
            "area1 area2 area3 area3 area4";
    }
    @media (max-width: ${breakpoint2}) {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas:
            "area1 area2 area2"
        ;
    }
    @media (max-width: ${breakpoint3}) {
        grid-template-columns: 1fr;
        grid-template-areas:
            "area1"
        ;
    }
`

const StyledSmallTitle1 = styled(SmallTitle)`
    margin-left: 20%;
    @media (max-width: ${breakpoint1}) {
        grid-area: area3;
        margin-left: 0;
        text-align: center;
    }
    @media (max-width: ${breakpoint2}) {
        grid-area: area2;
    }
    @media (max-width: ${breakpoint3}) {
        grid-area: area1;
    }
`
const StyledSmallTitle2 = styled(SmallTitle)`
margin-left: 30px;
    @media (max-width: ${breakpoint1}) {
        display: none;
    }

`

const Front = () => {
    const { lpTitle = '', lpDesc = '', colorTiles = [{prefixField:'',numberField:'',textField:''}] } = useFront() || {};


    return (
        <StyledFront id='top'>
            <Grid2>
                <StyledSmallTitle1>Inspirujemy. Projektujemy. Wdrażamy.</StyledSmallTitle1>
                <StyledSmallTitle2>Poznaj nasze pakiety:</StyledSmallTitle2>
            </Grid2>
            <Grid>
                <Header>
                    {lpTitle}
                </Header>
                <PinkBox>
                    <Content>
                        <BoxTitle1>
                            <Title>{colorTiles[0]?.prefixField}</Title>
                            <Number>0{colorTiles[0]?.numberField}</Number>
                        </BoxTitle1>
                        <BoxTitle2>{colorTiles[0]?.textField}</BoxTitle2>
                    </Content>
                </PinkBox>
                <OrangeBox>
                    <Content>
                        <BoxTitle1>
                            <Title>{colorTiles[1].prefixField}</Title>
                            <Number>0{colorTiles[1].numberField}</Number>
                        </BoxTitle1>
                        <BoxTitle2>{colorTiles[1].textField}</BoxTitle2>
                    </Content>
                </OrangeBox>
                <GreenBox />
                <BlueBox>
                    <Content>
                        <BoxTitle1>
                            <Title>{colorTiles[2].prefixField}</Title>
                            <Number>0{colorTiles[2].numberField}</Number>
                        </BoxTitle1>
                        <BoxTitle2>{colorTiles[2].textField}</BoxTitle2>
                    </Content>
                </BlueBox>
                <WhiteBox>
                    <Description>
                        <span>Ask me anything. </span>{lpDesc}
                    </Description>
                    <Link to={"/configurator"}><Button bgColor='lightOrange' icon={<SwitchIcon />}>skonfiguruj ofertę</Button></Link>
                </WhiteBox>
            </Grid>
        </StyledFront>
    )
}

export default Front
