import React, {useState} from 'react'
import styled from 'styled-components'
import Textfield from '../TextField/Textfield'
import Button from '../Button/Button'
import SendIcon from '../SendIcon/SendIcon'
import Switch from '../Switch/Switch'
import {useField} from "formik";


const breakpoint1 = '500px'

const ContactBoxWrapper = styled.div`
    background-color: ${props => props.theme.colors.blue};
    border: 8px solid ${props => props.theme.colors.lightOrange};
    color: ${props => props.theme.colors.lightOrange};
    text-align: center;
    border-radius: 20px;
    position: relative;
    padding: 30px;
`

const Title = styled.h3`
    font-size: 22px;
    font-weight: bold;
    line-height: 43px;
    padding: 0 20px;
`

const SubTitle = styled.h4`
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
    opacity: 0.8;
`

const Chat = styled.div`
    width: 100px;
    height: 75px;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -8px);
    z-index: 2;
    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 50%;
        height: 30px;
        background-color: ${props => props.theme.colors.orange};
        transform: translateY(50%) skewY(30deg);
        z-index: 1;
    }
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${props => props.theme.colors.pink};
        z-index: 2;
    }
`
const Wrapper = styled.div`
    display: flex;
    margin: 15px 0px 30px 0px;
    .sendBtn {
        transform: translateX(70px);
    }
    @media(max-width: ${breakpoint1}) {
        flex-direction: column;
        .sendBtn {
            transform: none;
            margin-top: 20px;
        }
    }
`

const ContactBox = (props) => {
    const [active, setActive] = useState(false);

    const field = {
        name: 'accept',
        value: 1,
        checked: active,
        onChange: () => {
            setActive(!active);
        }
    };

    return (
        <ContactBoxWrapper>
            <Title>Zostaw swój numer.</Title>
            <SubTitle> Zadzwonimy do Ciebie i wspólnie dobierzemy ofertę.</SubTitle>
            <Wrapper>
                <Textfield noFormik fullWidth placeholder='Telefon'/>
                <Button className='sendBtn' bgColor='pink' color='blue' icon={<SendIcon/>}>Wyślij</Button>
            </Wrapper>
            <Switch align={"center"} isActive={field.checked} field={field}
                    label='Akceptuje that’s what we’re talking about here. A perfect balance between fudgy and cakey so everyone gets what they want out of these brownies. We’ll leave it to you to fight over who gets the crisp edges versus the ooey-gooey middles. Let’s get baking! '/>
            <Chat/>
        </ContactBoxWrapper>
    )
}

export default ContactBox