import React from 'react'
import pig from '../../images/pig.svg'
import circles from '../../images/circles.svg'
import lock from '../../images/lock.svg'
import finger from '../../images/finger.svg'
import Tile from './components/Tile'
import styled from 'styled-components'

const tilesImages = [pig, finger, lock, circles]

const tilesColor = ['#0138AC', '#1D804D', '#0138AC', '#F59CBA']

const StyledTiles = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1064px;
    gap: 10px;
    z-index: 100;
`

const Tiles = ({ data }) => {
    return (
        <StyledTiles>
            {
                data.map((tile, index) => <Tile key={index} data={tile} img={tilesImages[index]} color={tilesColor[index]} />)
            }
        </StyledTiles>
    )
}

export default Tiles