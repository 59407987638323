import React from "react"
import Layout from "../components/Layout/Layout"
import Theme from "../components/Theme/Theme"
import Nav from "../components/Nav/Nav"
import Front from "../sections/Front"
import Card from "../sections/Card"
import Contact from "../sections/Contact"
import Realizations from "../sections/Realizations"
import DidYouKnow from "../sections/DidYouKnow"
import Footer from "../sections/Footer"


const IndexPage = () => {
  return (
    <Layout>
      <Theme>
        <Nav />
        <Front />
        <Card />
        <Contact />
        <Realizations />
        <DidYouKnow />
        <Footer />
      </Theme>
    </Layout>
  )
}

export default IndexPage
