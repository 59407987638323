import React from 'react'
import styled from 'styled-components'
import circle from '../images/circle.svg'
import building from '../images/building.svg'
import girl from '../images/girl.jpg'
import SmallTitle from '../components/SmallTitle/SmallTitle'
import BigTitle from '../components/BigTitle/BigTitle'
import ContactBox from '../components/ContactBox/ContactBox'
import { useContact } from '../services/contact/useContact'


const breakpoint1 = '1500px'
const breakpoint2 = '1200px'
const breakpoint3 = '900px'
const breakpoint4 = '500px'


const Circle = styled.img`
    padding-bottom: 270px;
    display: block;
    width: 270px;
    position: relative;
    z-index: 2;
    align-self: flex-start;
    @media (max-width: ${breakpoint2}) {
        width: 150px;
    }
    @media (max-width: ${breakpoint3}) {
        display: none;
    }

`

const Building = styled.img`
    padding-top: 270px;
    display: block;
    width: 270px
    position: relative;
    z-index: 2;
    align-self: flex-end;
    @media (max-width: ${breakpoint2}) {
        width: 150px;
    }
    @media (max-width: ${breakpoint3}) {
        display: none;
    }

`

const StyledContact = styled.div`
    background-color: #EF512C;
    position: relative;
    z-index: 1;
    min-height: 555px;
    color: white;
    display: flex;
    gap: 5%;
    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: url(${girl});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        mix-blend-mode: multiply;
    }
`
const Content = styled.div`
    position: relative;
    z-index: 2;
    display: flex;
    gap: 100px;
    align-self: center;
    padding: 50px 0px;
    @media (max-width: ${breakpoint1}) {
        flex-direction:column;
        gap: 50px;
    }
    @media (max-width: ${breakpoint3}) {
        margin: 0 20px;
    }
`

const Titles = styled.div`
@media (max-width: ${breakpoint3}) {
    text-align: center;
}
`

const ContactBoxWrapper = styled.div`
    @media (max-width: ${breakpoint3}) {
        padding-right: 32px;
        padding-left: 42px;
    }
    @media (max-width: ${breakpoint4}) {
        padding-right: 0px;
        padding-left: 42px;
    }
`

const Contact = () => {

    const { s4Title = '', s4prefix = '' } = useContact() || {}

    return (
        <StyledContact id='contact'>
            <Circle src={circle} />
            <Content>
                <Titles>
                    <SmallTitle color='lightOrange'>{s4prefix}</SmallTitle>
                    <BigTitle color='lightOrange'>{s4Title}</BigTitle>
                </Titles>
                <ContactBoxWrapper>
                    <ContactBox />
                </ContactBoxWrapper>
            </Content>
            <Building src={building} />
        </StyledContact>
    )
}

export default Contact
