import { useStaticQuery, graphql } from "gatsby";

export const useCardTitles = () => {
  const data = useStaticQuery(graphql`
    query CardTitlesQuery {
        wpPage(id: {eq: "cG9zdDoy"}) {
          s2Text
          s2Title
          s2prefix
        }
      }
      
    `
  )
  return data?.wpPage
}

