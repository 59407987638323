import React from 'react'
import styled from 'styled-components'
import {SplideSlide} from '@splidejs/react-splide';
import Button from '../../../Button/Button'
import SwitchIcon from '../../../SwitchIcon/SwitchIcon'
import {Link} from "gatsby";

const breakpoint1 = '1200px'
const breakpoint2 = '750px'

const Image = styled.img`
  width: 100%;
  height: auto;
  border-radius: 20px;
  mix-blend-mode: multiply;
`

const Gradient = styled.div`
  background: rgb(51, 51, 51);
  background: linear-gradient(0deg, rgba(51, 51, 51, 1) 0%, rgba(35, 35, 35, 0.69) 40%, rgba(0, 0, 0, 0) 100%);
  border-radius: 20px;
  position: relative;
  top: 0;
  z-index: 1;
  transition: all 0.3s ease-in-out;
  opacity: 0.5;
  @media (max-width: ${breakpoint2}) {
    opacity: 1;
  }

`

const Content = styled.div`
  width: 100%;
  height: 100%;
  padding: 10%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: ${props => props.theme.colors.lightOrange};
  position: absolute;
  z-index: 2;

`

const SingleSlideStyled = styled.div`
  position: relative;

`

const ExtraContent = styled.div`
  display: none;
`

const SplideSlideStyled = styled(SplideSlide)`
  border-radius: 20px;

  &.is-next {
    ${Gradient} {
      transform: scale(1.4) translateY(25px);
      box-shadow: 40px 40px 40px 1px rgba(49, 9, 0, 0.4);
      opacity: 1;
      @media (max-width: ${breakpoint1}) {
        box-shadow: 30px 30px 30px 1px rgba(49, 9, 0, 0.4);
      }
      @media (max-width: ${breakpoint2}) {
        transform: none;
        box-shadow: none
      }
    }

    ${SingleSlideStyled} {
      z-index: 3;
    }

    ${ExtraContent} {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    ${Content} {
      width: calc(100% + 60px);
      height: calc(100% + 60px);
      padding: 0;
      transform: translate(-30px, -5px);
      @media (max-width: ${breakpoint2}) {
        width: 100%;
        height: 100%;
        padding: 20px;
        transform: none;
      }
    }
  }
`


const PackageBox = styled.div`
  display: flex;
  justify-content: space-between;
`

const Package = styled.div`
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
`
const Number = styled.div`
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 2px;
`

const Title = styled.div`
  font-size: 21px;
  font-weight: 600;
  border-bottom: 1px solid white;
  color: white;
  max-width: 50%;
  margin-bottom: 10px;
`

const Description = styled.div`
  font-size: 14px;
  line-height: 23px;
  margin-bottom: 40px;

`


const SingleSlide = ({slide, number}) => {
    const {title, description, imageGallery} = slide


    return (
        <SplideSlideStyled>
            <SingleSlideStyled>
                <Content>
                    <PackageBox>
                        <Package>Pakiet</Package>
                        <Number>{number < 10 ? `0${number}` : number}</Number>
                    </PackageBox>
                    <Title>{title}</Title>
                    <ExtraContent>
                        <Description>{description}</Description>
                        <Link to={"/configurator"}><Button icon={<SwitchIcon/>} bgColor='lightOrange' color='gray'>Skonfiguruj ofertę</Button></Link>
                    </ExtraContent>
                </Content>
                <Gradient>
                    <Image src={imageGallery?.url}/>
                </Gradient>
            </SingleSlideStyled>
        </SplideSlideStyled>
    )
}

export default SingleSlide
