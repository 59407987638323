import { useStaticQuery, graphql } from "gatsby";

export const useFront = () => {
    const data = useStaticQuery(graphql`
    query FrontQuery {
        wpPage {
          lpTitle
          lpDesc
          colorTiles {
            numberField
            prefixField
            textField
          }
        }
      }
    `)
    return data?.wpPage
}
