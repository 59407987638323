import { useStaticQuery, graphql } from "gatsby";

export const usePossibilitiesData = () => {
  const data = useStaticQuery(graphql`
    query PossibilitisQuery {
        wpPage(id: {eq: "cG9zdDoy"}) {
          s2Bullets {
            id
            text
          }
        }
      }
    `)
  return data?.wpPage?.s2Bullets
}

