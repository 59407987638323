import React, {useState} from 'react'
import styled, {css} from 'styled-components'
import Switch from '../../Switch/Switch'
import {useField} from "formik";

const activedTile = css`
    transform: translateY(-20px);
    box-shadow: 40px 40px 40px 1px rgba(49,9,0,0.4);
    z-index: 100;
    border: 8px solid ${props => props.theme.colors.lightOrange};
    width: 250px;
    height: 250px;
`

const TileStyled = styled.div`
    width: 240px;
    height: 240px;
    background-color: ${props => props.color};
    border-radius: 20px;
    padding: 50px 15px 15px 15px;
    color: ${props => props.theme.colors.white};
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    font-size: 13px;
    transition: all 0.3s ease-in-out;
    border: 0px solid ${props => props.theme.colors.lightOrange};
    ${props => props.isActive && activedTile}

`

const FirstTitle = styled.div`
    font-size: 23px;
    font-weight: bold;
    line-height: 43px;
`

const SecondTitle = styled.div`
    font-weight: bold;
    border-bottom: 1px solid;
    margin-bottom: 5px;
`

const Image = styled.img`
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    z-index: 1;
`

const Description = styled.div`
    line-height: 28px;
`

const Front = styled.div`
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 2;
    top:0;
    left: 0;
    padding: 50px 15px 15px 15px;
    transition: opacity 0.3s ease-in-out;
    opacity: ${props => props.isActive ? '0' : '1'};
`

const Back = styled.div`
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 2;
    top:0;
    left: 0;
    padding: 20px;
    transition: opacity 0.3s ease-in-out;
    opacity: ${props => props.isActive ? '1' : '0'};
`

const Tile = ({data, color, img, ...props}) => {

    const [active, setActive] = useState(false);
    const {title, title1, description} = data

    const field = {
        name: 'title',
        value: 1,
        checked: active,
        onChange: () => {
            setActive(!active);
        }
    };

    return (
        <TileStyled color={color} isActive={field.checked}>
            <Image src={img}/>
            <Front isActive={field.checked}>
                <FirstTitle>
                    {title}
                </FirstTitle>
            </Front>
            <Back isActive={field.checked}>
                <SecondTitle>
                    {title1}
                </SecondTitle>
                <Description>
                    {description}
                </Description>
            </Back>
            <Switch isActive={field.checked} field={field}/>
        </TileStyled>
    )
}

export default Tile