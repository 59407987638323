import { useStaticQuery, graphql } from "gatsby";

export const useWhyWorth = () => {
  const data = useStaticQuery(graphql`
    query WhyWorthQuery {
      wpPage(id: {eq: "cG9zdDoy"}) {
        s3Title
        s3prefix
        s3Tiles {
          description
          title
        }
      }
    }
    
    `
  )
  return data?.wpPage
}

