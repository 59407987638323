import { useStaticQuery, graphql } from "gatsby";

export const useCardGallery = () => {
  const data = useStaticQuery(graphql`
    query CardGalleryQuery {
        wpPage(id: {eq: "cG9zdDoy"}) {
          s2Gallery {
            imageGallery {
              alt
              caption
              description
              full_url
              height
              name
              path
              srcset
              title
              url
              width
            }
            description
            title
          }
        }
      }
      
    `)
  return data?.wpPage?.s2Gallery
}

