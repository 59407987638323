import React from 'react'
import { Splide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import SingleSlide from './components/SingleSlide';
import styled from 'styled-components';
import { useCardGallery } from '../../../services/card/useCardGallery'

const breakpoint1 = '1200px'
const breakpoint2 = '750px'


const SplideStyled = styled(Splide)`
    .splide__track {
        padding-top: 7%;
        padding-bottom: 20%;
        margin-top: -7%;
    }
    .arrowNext {
        top:0;
        right: 0;
        transform: none;
        border-radius: 0;
        background-color: #F59CBA;
        width: 24px;
        height: 24px;
        svg {
            display: none;
        }
        &:before {
            content: '';
            position: absolute;
            width: 0; 
            height: 0; 
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
            border-left: 8px solid #EF512C;
        }

    }
    .arrowPrev {
        top:0;
        right: 0;
        left: initial;
        transform: none;
        border-radius: 0;
        background-color: #EDE4D3;
        width: 24px;
        height: 24px;
        transform: translateX(-100%);
        svg {
            display: none;
        }
        &:before {
            content: '';
            position: absolute;
            width: 0; 
            height: 0; 
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent; 
            border-right: 8px solid #0138AC; 
        }
    }
    @media (max-width: ${breakpoint1}) {
        .splide__track {
            padding-bottom: 25%;
        }
    }
    @media (max-width: ${breakpoint2}) {
        .arrowNext {
            right: initial;
            top: initial;
            left: 50%;
            bottom: 0;
            transform: none;
            
        }
        .arrowPrev {
            right: initial;
            top: initial;
            left: 50%;
            bottom: 0;
            transform: translateX(-100%)
        }
        .splide__track {
            padding-top: 7%;
            padding-bottom: 10%;
            margin-bottom: 10%;
        }
    }
`

const Slider1 = () => {
    const dataGallery = useCardGallery()
    return (
        <SplideStyled
            options={{
                type: 'loop',
                perPage: 3,
                perMove: 1,
                focus: 0,
                updateOnMove: true,
                pagination: false,
                classes: {
                    prev: 'splide__arrow--prev arrowPrev',
                    next: 'splide__arrow--next arrowNext',
                },
                breakpoints: {
                    750: {
                        perPage: 1,
                        focus: -1,
                    },
                }
            }}
        >
            {
                (dataGallery || []).map((slide, index) => (
                    <SingleSlide key={index} slide={slide} number={index + 1} />
                ))
            }
        </SplideStyled>
    )
}

export default Slider1
